// Footer.js
import React from 'react';
import { Container } from 'reactstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3">
      <Container className="text-center">
        <p>
          Designed and Developed by Ezhil | &copy; 2024
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
