// Header.js
import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import FinutilsLogo from '../app/assets/img/fu_logo.png';

const Header = ({ scrollToAboutUs, scrollToToolsList, scrollToToolsSpace }) => {
  return (
    <Navbar color="primary" dark expand="md" className="mb-4">
      <Container className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <NavbarBrand href="/">
          <img src={FinutilsLogo} alt="Company Logo" className="App-logo" style={{ margin: 0, padding: 0 }}/>
          </NavbarBrand>
        </div>
        <div className="text-light">
          <div>FinUtils</div>
        </div>
        <Nav navbar className="ml-auto">
          <NavItem>
            <NavLink href="#" onClick={scrollToAboutUs} className="text-light">
              About Us
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" onClick={scrollToToolsList} className="text-light">
              Tools List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" onClick={scrollToToolsSpace} className="text-light">
              Tools Space
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
