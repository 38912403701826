// App.js
import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import AboutUs from './components/AboutUs'; // Import your AboutUs component
import ToolsList from './components/ToolsList'; // Import your ToolsList component
import ToolsSpace from './components/ToolsSpace'; // Import your ToolsSpace component
import Footer from './components/Footer';

const App = () => {
  const aboutUsRef = useRef(null);
  const toolsListRef = useRef(null);
  const toolsSpaceRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <div>
        <Header
          scrollToAboutUs={() => scrollToSection(aboutUsRef)}
          scrollToToolsList={() => scrollToSection(toolsListRef)}
          scrollToToolsSpace={() => scrollToSection(toolsSpaceRef)}
        />
        <div ref={aboutUsRef}>
          <AboutUs />
        </div>
        <div ref={toolsListRef}>
          <ToolsList />
        </div>
        <div ref={toolsSpaceRef}>
          <ToolsSpace />
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
